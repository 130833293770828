import {css} from 'styled-components';
import logoHum from '../icons/hum/logo-humanitas.png';
import progress1 from '../icons/hum/step-indicator-1.svg';
import progress2 from '../icons/hum/step-indicator-2.svg';
import progress3 from '../icons/hum/step-indicator-3.svg';
import progress1of2 from '../icons/hum/step-indicator-1of2.svg';
import progress2of2 from '../icons/hum/step-indicator-2of2.svg';
import whatsapp from '../icons/sho/whatsapp.png';
import facebook from '../icons/sho/facebook.svg';
import linkedin from '../icons/sho/linkedin.svg';
import instagram from '../icons/sho/instagram.svg';
import twitter from '../icons/sho/x.svg';

const primaryColor = '#0090d2';
const primary = '#0090d2';
const hover = '#f4c29a';
const errorColor = '#d71f28';

export default {
  // GlobalStyles
  bodyFontFamily: 'Inter',
  h1FontFamily: '"Inter", sans-serif',
  h3FontFamily: '"Inter", sans-serif',
  h1FontSize: '22px',
  bodyFontSize: '15px',
  telemarketingText: {
    color: 'rgb(153,153,153)',
    fontSize: '11px',
    lineHeight: '1rem'
  },
  // AmountButton
  amountButton: {
    color: primaryColor,
    fontFamily: '"Inter", sans-serif',
    fontSize: '17px',
    width: '94px',
    height: '78px',
    border: '1px solid #C4C4C4',
    borderRadius: '4px',
    backgroundColor: '#fff',
    backgroundColorActive: 'rgba(0,144,210,0.10)',
    borderHover: `1px solid ${primaryColor}`,
    boxShadowHover: `inset 0px 0px 0px 1px ${primaryColor}`
  },
  buttonContainerSpaceBetween: {
    width: '94px'
  },
  //BackButton
  backButton: {
    fontSize: '15px',
    color: '#1A1A1A'
  },
  // Box
  boxComponent: {
    borderRadius: '10px'
  },
  // Button
  button: {
    borderRadius: '5px',
    fontFamily: '"Inter", sans-serif',
    fontSize: '20px',
    height: '56px',
    backgroundColor: '#d71f28',
    color: '#fff',
    boxShadow: 'none',
    active: css`
      background-color: ${hover};
      border-bottom: none;
      box-shadow: none !important;
    `,
    hover: css`
      background-color: ${hover};
    `,
    focus: css`
      box-shadow: none;
    `
  },
  background: css`
    background-image: ${props => `url('${props.backgroundImageUrl}')`};
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    position: fixed;
    top: -10px;
    left: -10px;
    bottom: -10px;
    right: -10px;
    z-index: -10;
    background-size: cover;

    @media (min-width: 768px) and (max-width: 1024px) {
      background-image: ${props => `url('${props.backgroundImageTabletUrl}')`};
    }

    @media (max-width: 767px) {
      background-image: ${props => `url('${props.backgroundImageMobileUrl}')`};
      //position: absolute;
      width: 100%;
      height: 100%;
      background-color: white;
      background-size: contain;
      background-position-y: top;
      background-position-x: center;
      background-repeat: no-repeat;
      inset: initial;
      z-index: -1;
    }
  `,
  // Checkbox
  inputCheckbox: {
    border: '1px solid #C4C4C4'
  },
  box: {
    fontSize: '15px',
    colorHasError: errorColor,
    borderHasError: `1px solid ${errorColor}`,
    borderHover: '1px solid #818181',
    backgroundColorChecked: primaryColor,
    backgroundBorderChecked: '1px solid #199A57'
  },
  // DateInput
  dateDropdown: {
    padding: '15.6px 30px 12.4px 10px',
    border: '1px solid #c4c4c4',
    boxShadowFocus: '0 0 0 3px #c4c4c4',
    borderFocus: '1px solid transparent',
    boxShadowError: '0 0 0 1px #E8141E',
    boxShadowValid: '0 0 0 1px #199A57'
  },
  // DisplayIban
  iban: {
    fontFamily: '"Inter", sans-serif',
    color: '#fff'
  },
  // Dropdown
  dropdown: {
    height: '46px',
    borderRadius: '4px',
    backgroundColor: '#fff',
    fontSize: '18px',
    paddingLeft: '13px',
    border: '1px solid #c4c4c4',
    borderFocus: '1px solid transparent',
    boxShadowFocus: '0 0 0 2px #818181'
  },
  // ErrorMessage
  error: {
    backgroundColor: '#f3f3f3',
    color: '#e8141e',
    height: '49px'
  },
  // Iban
  ibanContainer: {
    display: 'none'
  },
  // Input
  inputComponent: {
    fontSize: '15px',
    border: '1px solid #c4c4c4',
    borderFocus: '1px solid #818181',
    boxShadowFocus: 'none',
    colorPlaceholder: '#c4c4c4',
    borderValid: '1px solid #199a57',
    boxShadowValid: 'none',
    backgroundColorValid: '#F3FAF6',
    borderError: '1px solid #E8141E',
    boxShadowError: 'none',
    colorError: '#E8141E',
    backgroundColorError: '#FFE5E6'
  },
  // TextArea
  textAreaComponent: {
    fontSize: '15px',
    border: '1px solid #c4c4c4',
    borderFocus: '1px solid #818181',
    boxShadowFocus: 'none',
    colorPlaceholder: '#c4c4c4',
    borderValid: '1px solid #199a57',
    boxShadowValid: 'none',
    backgroundColorValid: '#F3FAF6',
    borderError: '1px solid #E8141E',
    boxShadowError: 'none',
    colorError: '#E8141E',
    backgroundColorError: '#FFE5E6'
  },
  // Label
  optionalLabel: {
    display: 'none',
    color: '#818181'
  },
  label: {
    fontSize: '15px',
    color: '#000',
    colorError: '#E8141E'
  },
  logo: {
    width: '140px',
    height: '75px',
    backgroundImage: logoHum,
    widthMobile: '120px',
    heightMobile: '103px',
    margin: '0',
    marginMobile: '0'
  },
  // PaymentButton
  paymentButton: {
    color: primaryColor,
    fontFamily: '"Inter", sans-serif;',
    fontSize: '12px',
    width: '115px',
    height: '78px',
    lineHeight: '78px',
    border: '1px solid #C4C4C4',
    borderRadius: '4px',
    backgroundColor: '#fff',
    backgroundColorActive: 'rgba(0,144,210,0.10)',
    borderHover: `1px solid ${primaryColor}`,
    boxShadowHover: `inset 0px 0px 0px 1px ${primaryColor}`
  },
  // RadioButton
  inputRadioButton: {
    backgroundColor: '#fff',
    border: '1px solid #c4c4c4',
    backgroundColorActive: '#85a846',
    borderActive: '1px solid #85a846'
  },
  // ProgressBar
  progressBar: {
    step1: css`
      width: 70px;
      background: url(${progress1}) no-repeat 0;
    `,
    step2: css`
      width: 70px;
      background: url(${progress2}) no-repeat 0;
    `,
    step3: css`
      width: 70px;
      background: url(${progress3}) no-repeat 0;
    `,
    step1of2: css`
      width: 40px;
      background: url(${progress1of2}) no-repeat 0;
    `,
    step2of2: css`
      width: 40px;
      background: url(${progress2of2}) no-repeat 0;
    `
  },
  radio: {
    borderHover: '1px solid #818181',
    colorHasError: errorColor,
    borderHasError: '1px solid #e8141e'
  },
  // TeaserHeader
  teaser: {
    display: 'inline',
    fontFamily: '"Inter", sans-serif',
    fontWeight: 'normal',
    fontSize: '30px',
    lineHeight: '36px',
    fontSizeTablet: '30px',
    lineHeightTablet: '36px',
    fontSizeWide: '34px',
    lineHeightWide: '41px',
    subtitleFontFamily: '"Inter", sans-serif',
    subtitleFontSize: '20px',
    subtitleLineHeight: '22px',
    subtitleFontSizeWide: '20px',
    subtitleLineHeightWide: '22px',
    subtitleFontSizeTablet: '20px',
    subtitleLineHeightTablet: '22px',
    backgroundColor: 'rgba(0,144,210,0.80)',
    backgroundColorSubtitle: 'rgba(255,255,255,0.80)',
    color: '#fff',
    colorSubtitle: '#000',
    padding: '0 10px',
    paddingTop: '0',
    paddingTopSubtitle: '0',
    topLeftTextAlignment: 'left',
    topRightTextAlignment: 'right',
    bottomLeftTextAlignment: 'left',
    bottomRightTextAlignment: 'right'
  },
  teaserContainer: {
    margin: '0'
  },
  // Title
  title: {
    color: '#fff',
    fontFamily: '"Inter", sans-serif',
    fontSize: '18px',
    height: '65px',
    fontWeight: 'normal',
    borderRadius: '10px',
    backgroundColor: primary
  },
  // Validation
  validation: {
    color: '#e8141e',
    fontSize: '15px'
  },
  // OptIn
  optInButton: {
    fontFamily: '"Inter", sans-serif',
    fontSize: '20px',
    backgroundColor: '#000',
    color: primary,
    boxShadow: 'none',
    active: css`
      background-color: #000;
      border-bottom: none;
      box-shadow: none !important;
    `,
    hover: css`
      background-color: #000;
    `,
    focus: css`
      box-shadow: none;
    `
  },
  // SocialShareButton
  socialShareButton: {
    backgroundSize: '50px',
    color: '#000',
    whatsapp: css`background-image: url(${whatsapp})`,
    facebook: css`background-image: url(${facebook})`,
    twitter: css`background-image: url(${twitter})`,
    linkedin: css`background-image: url(${linkedin})`,
    instagram: css`background-image: url(${instagram})`
  },
  mobileGridTemplateRows: '180px 1fr auto 100px'
};
